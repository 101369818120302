import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import { createGtm } from "@gtm-support/vue-gtm";
import App from "./App.vue";
import cms from "./cms";
import Markdown from "vue3-markdown-it";
import { createI18n } from "vue-i18n";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import("./Welcome").catch((err) => {
        console.log(err);
        return "Fu";
      }),
  },
  {
    path: "/invited",
    name: "Invited",
    component: () =>
      import("./Invited").catch((err) => {
        console.log(err);
        return "Oops... " + err;
      }),
  },
  {
    path: "/room/:roomId/:roomCode",
    name: "Room",
    component: () =>
      import("./Room").catch((err) => {
        console.log(err);
        return "Oops... " + err;
      }),
    props: true,
  },
  {
    path: "/roomless",
    name: "Roomless",
    component: () =>
      import("./Roomless").catch((err) => {
        console.log(err);
        return "Oops... " + err;
      }),
    props: false,
  },
  {
    path: "/resources",
    name: "Resources",
    component: () =>
      import("./DynamicResources").catch((err) => {
        console.log(err);
        return "Ooops..." + err;
      }),
    props: false,
  },
  {
    path: "/dynamic-resources",
    name: "DynamicResources",
    component: () =>
      import("./DynamicResources").catch((err) => {
        console.log(err);
        return "Oops... " + err;
      }),
    props: false,
  },
  {
    path: "/endgame",
    name: "EndGame",
    component: () =>
      import("./components/EndGame").catch((err) => {
        console.log(err);
        return "Ooops..." + err;
      }),
    props: false,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
      };
    }
  },
});

import en from "./locales/en.json";
import frCA from "./locales/fr-CA.json";

const i18n = createI18n({
  locale: "en", // set current locale
  fallbackLocale: "en", // set fallback locale
  messages: {
    en: en,
    "fr-CA": frCA,
  },
});

const app = createApp(App);

app.config.devtools = true;
app.config.performance = true;

app.use(router);
app.use(cms);
app.use(Markdown);
app.use(i18n);

import AOS from "aos";
import "aos/dist/aos.css";
app.AOS = new AOS.init({});

// Google GTM
app.use(
  createGtm({
    id: "GTM-N6M2G7Q",
    defer: false,
    compatibility: false,
    enabled: true,
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true,
    // vueRouter: router,
  })
);

import VueCookies from "vue-cookies";
app.use(VueCookies, { expire: "1d" });

app.mount("#app");
