import { reactive, readonly } from "vue";

// VUE plugin to fetch info from CMS

function getCMSContent(name) {
  return fetch(`/cms/${name}`).then((res) => res.json());
}

function getResource(name) {
  return getCMSContent(name);
}


function linkSingleToCMS(name, attr, key, cms) {
  getCMSContent(name).then((content) => {
    if (content[attr] && content[attr].length > 0) {
      cms[key] = content[attr];
    }
  });
}

function linkMultiToCMS(name, attrs, key, cms) {
  getCMSContent(name).then((content) => {
    let target = cms[key];
    if (!target) {
      target = reactive({});
      cms[key] = target;
    }
    attrs.forEach((attr) => {
      if (content[attr] && content[attr].length > 0) {
        target[attr] = content[attr];
      }
    });
  });
}

function extractTitleAndDesc(payload) {
  return {
    Title: payload.Title,
    Subtitle: payload.Subtitle,
    Desc: payload.Description,
  };
}

function extractFeature(payload) {
  return {
    Text: extractTitleAndDesc(payload.Text),
    Image: extractImage(payload.Image),
    Sticker: extractImage(payload.Sticker),
  };
}

function extractImage(payload) {
  return {
    url: `/cms${payload.url}`,
    alt: payload.alternativeText,
  };
}

function extractFeatureList(payload) {
  const list = [];
  payload.forEach((entry) => {
    list.push(extractFeature(entry));
  });
  return list;
}

function initializeCMS() {
  // Load CMS with default initial data
  const cms = reactive({
    getResource,
    extractImage,
    privacyPolicy: "Loading...",
    homepage: {
      Header: {
        Title:
          "Likely Story is a game that helps you figure out how to deal with awkward relationship situations.",
        Desc: "Ready to find out what your friends think is normal about love and sex?",
      },
      Footer:
        "Click invite players to share the link to your friends. " +
        "You need a minimum of 3 players to start the game.",
      How: [],
    },
    alerts: {
      Time: "",
      Choose: "",
      Waiting: "",
      Winner: "",
    },
    avatars: [
      { alt: "1" },
      { alt: "2" },
      { alt: "3" },
      { alt: "4" },
      { alt: "5" },
      { alt: "6" },
      { alt: "7" },
    ],
  });

  // Now fetch the actual data from the CMS
  linkSingleToCMS("privacy-policy", "Text", "privacyPolicy", cms);
  linkMultiToCMS(
    "alerts",
    ["Time", "Choose", "Waiting", "SelectWinner"],
    "alerts",
    cms
  );

  getCMSContent("homepage").then((homepage) => {
    const content = {};
    content.Footer = homepage.Footer;
    content.Header = extractTitleAndDesc(homepage.Header);
    content.How = extractFeatureList(homepage.How);
    cms.homepage = content;
  });

  getCMSContent("player-avatars").then((list) => {
    // debugger // eslint-disable-line no-debugger
    const avatars = [{ alt: "no player 0" }];
    console.log("Loading Player Avatars from CMS");
    list.forEach((entry) => {
      console.log("Got Avatar entry:", entry);
      try {
        avatars.push(extractImage(entry.Avatar));
      } catch (e) {
        // debugger // eslint-disable-line no-debugger
        console.log("Failed to process player avatar", entry, e);
      }
    });
    console.log("Finished Player Avatar fetching: ", avatars);
    cms.avatars = avatars;
  });

  getCMSContent("offboarding").then((offboarding) => {
    console.log("OFFBOARDING:", offboarding);
    const content = {};
    content.Header = extractTitleAndDesc(offboarding.Header);
    content.Footer = offboarding.Footer;
    content.stages = [];
    offboarding.Stages.forEach((s) => {
      content.stages.push({
        Content: extractTitleAndDesc(s.Content),
        Explore: s.Explore,
      });
    });
    console.log(content);
    cms.offboarding = content;
  });

  return readonly(cms);
}

export default {
  install: (app) => {
    app.config.globalProperties.$cms = initializeCMS();
  },
};
