<template>
  <div>
    <div class="mobile-view">
      <router-view @main-page="mainPage" />
    </div>
  </div>
</template>

<script>
import LogRocket from 'logrocket';
import { LANDING_PAGE_URL } from "/src/consts/urls";

export default {
  name: "Likely Story",
  components: {
  },
  data: () => ({
    comingSoon: Node.env == "production",
    roomId: null,
  }),
  computed: {},
  methods: {
    newGame: function () {
      fetch("/api/v1/start", { method: "POST" })
        .then((body) => body.json())
        .then((payload) => {
          console.log(payload);
          this.roomId = payload.id;
          this.$router.push(`/room/${payload.id}/${payload.code}`);
        });
    },
    newSinglePlayerGame: function () {
      fetch("/api/v1/start-single-player", { method: "POST" })
        .then((body) => body.json())
        .then((payload) => {
          console.log(payload);
          this.roomId = payload.id;
          this.$router.push(`/room/${payload.id}/${payload.code}?singlePlayer=true`);
        });
    },
    checkRouteAndStartGame: function () {
      const delayDuration = 400;
      const baseUrl = "/";

      setTimeout(() => {
        const localeParam = this.$route.query.locale;
        console.log("locale param after delay", localeParam);
        if (localeParam) {
          this.$i18n.locale = localeParam;
        }

        if (this.$route.path === baseUrl) {
          const singlePlayer = this.$route.query.singlePlayer;
          let path = this.$route.path
          console.log("path", path);

          if (singlePlayer === 'true') {
            this.newSinglePlayerGame();
          } else {
            this.newGame();
          }
        }
      }, delayDuration);
    },
    mainPage: function () {
      window.location.replace(LANDING_PAGE_URL)
    },
  },
  mounted: function () {
    LogRocket.init('wzmpqh/likely-story-production');
    this.checkRouteAndStartGame();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

@media only screen and (min-width: 700px) {}
</style>